<template>
	<div class="pui-form">
		<mpuertolocal-modals :modelName="modelName"></mpuertolocal-modals>
		<!--<pui-form-header
			v-if="modelLoaded"
			:showHeader="!isCreatingElement">
			<mpuertolocal-form-header
				:modelPk="modelPk"
			></mpuertolocal-form-header>
		</pui-form-header>-->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<pui-field-set :title="getTitleDatos">
						<v-layout wrap>
							<!-- IDPUERTOLOCAL -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idpuertolocal-mpuertolocal`"
									v-model="model.idpuertolocal"
									:label="$t('mpuertolocal.idpuertolocal')"
									disabled
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- CODPUE -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`codpue-mpuertolocal`"
									v-model="model.codpue"
									:label="$t('mpuertolocal.codpue')"
									disabled
									toplabel
									maxlength="5"
								></pui-text-field>
							</v-flex>
							<!-- NOMBRE -->
							<v-flex xs12 md3>
								<pui-text-field
									:id="`nombre-mpuertolocal`"
									v-model="model.nombre"
									:label="$t('mpuertolocal.nombre')"
									disabled
									toplabel
									maxlength="50"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- CODPAILOCODE -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`codpailocode-mpuertolocal`"
									v-model="model.codpailocode"
									:label="$t('mpuertolocal.codpailocode')"
									disabled
									toplabel
									maxlength="2"
								></pui-text-field>
							</v-flex>
							<!-- CODPUELOCODE -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`codpuelocode-mpuertolocal`"
									v-model="model.codpuelocode"
									:label="$t('mpuertolocal.codpuelocode')"
									disabled
									toplabel
									maxlength="3"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- NOMCAPMARITIMA -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`nomcapmaritima-mpuertolocal`"
									v-model="model.nomcapmaritima"
									:label="$t('mpuertolocal.nomcapmaritima')"
									disabled
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-flex>
							<!-- CODAP -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`codap-mpuertolocal`"
									v-model="model.codap"
									:label="$t('mpuertolocal.codap')"
									disabled
									toplabel
									maxlength="20"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- RECADUANERO -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`recaduanero-mpuertolocal`"
									v-model="model.recaduanero"
									:label="$t('mpuertolocal.recaduanero')"
									disabled
									toplabel
									maxlength="4"
								></pui-text-field>
							</v-flex>
							<!-- DESRECADUANERO -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`desrecaduanero-mpuertolocal`"
									v-model="model.desrecaduanero"
									:label="$t('mpuertolocal.desrecaduanero')"
									disabled
									toplabel
									maxlength="50"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- TELMMPPCONTACTO -->
							<v-flex xs12 md4>
								<pui-text-field
									:id="`telmmppcontacto-mpuertolocal`"
									v-model="model.telmmppcontacto"
									:label="$t('mpuertolocal.telmmppcontacto')"
									:disabled="formDisabled"
									toplabel
									maxlength="25"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12 md4>
								<pui-text-field
									:id="`reglamento-mpuertolocal`"
									v-model="model.reglamento"
									:label="$t('mpuertolocal.reglamento')"
									:disabled="formDisabled"
									toplabel
									maxlength="250"
								></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mpuertolocalActions from './MpuertolocalActions';
import mpuertolocalModals from './MpuertolocalModals.vue';

export default {
	name: 'mpuertolocal-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mpuertolocal-modals': mpuertolocalModals
	},
	data() {
		return {
			modelName: 'mpuertolocal',
			actions: mpuertolocalActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {},
	created() {}
};
</script>
